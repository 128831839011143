import React from 'react';
import { H2 as TamaguiH2 } from 'tamagui';
import type { TextProps } from 'tamagui';
import { skeleton } from '../../skeleton';

type Props = TextProps & { loading?: boolean; children?: React.ReactNode };

const H2 = ({ children, loading, ...restProps }: Props) => {
  return (
    // @ts-ignore (className)
    <TamaguiH2
      display="block"
      fontSize="$24"
      lineHeight="normal"
      fontWeight="$medium"
      {...restProps}
      className={skeleton.className('line', loading)}
    >
      {children}
    </TamaguiH2>
  );
};

export default H2;
