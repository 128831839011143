import { lazy, useRef } from 'react';
import { formatPrice } from '@holo/format';
import { Link } from '@holo/link';
import CounterInput from '../CounterInput/CounterInput';
import Button from '../Button/Button';
import Image from '../Image/Image';
import Text from '../Text/Text';
import XStack from '../XStack/XStack';
import YStack from '../YStack/YStack';
import Spinner from '../Spinner/Spinner';
import Circle from '../Circle/Circle';

const Select = lazy(() => import('../Select/Select'));

type Props = {
  href: string;
  name: string;
  onChangeQuantity: (quantity: number) => void;
  onChangeVariant: (variantId: string) => void;
  onAddItem: (variantId: string) => void;
  variantId: string;
  quantity: number;
  variants?: any;
  variant?: any;
  image?: string | null;
  gridLayout: boolean;
  noBottomBorder?: boolean;
  loading?: boolean;
};

const ProductListItem = ({
  name,
  gridLayout,
  href,
  image,
  variants,
  variant,
  quantity,
  variantId,
  noBottomBorder,
  onAddItem,
  onChangeQuantity,
  onChangeVariant,
  loading,
}: Props) => {
  const autoFocus = useRef(false);
  const variantOptions = variant
    ? [{ id: variant.id, label: variant?.name || variant?.unit?.name }]
    : variants.map((v: any) => ({
        id: v.id,
        label: `${v?.unit.quantity > 1 ? v?.unit.quantity : ''} ${v?.name || v?.unit?.name}`,
      }));

  const selectedVariant = variant ?? variants.find((v: any) => v.id === variantId);
  const borderBottomWidth = gridLayout || noBottomBorder ? 0 : 1;

  const handleOnAddItem = () => {
    autoFocus.current = true;
    onAddItem(variantId);
  };

  return (
    <XStack
      tag="li"
      borderBottomWidth={borderBottomWidth}
      py={10}
      px={0}
      space={gridLayout ? 0 : '$4'}
      borderBottomColor="$border"
      alignItems={gridLayout ? 'flex-start' : 'center'}
      flexDirection={gridLayout ? 'column' : 'row'}
      mb={gridLayout ? '$4' : 0}
      maxWidth={gridLayout ? 8 * 24 : 'auto'}
      width="100%"
    >
      <Link href={href} style={{ width: gridLayout ? '100%' : 'auto' }}>
        <Image
          width={gridLayout ? 8 * 24 : 8 * 10}
          height={gridLayout ? 8 * 24 : 8 * 10}
          alt={`${name}`}
          src={image}
          borderRadius={10}
          objectFit="cover"
          wrapperProps={{ minWidth: '80px' }}
          style={{ maxWidth: '100%' }}
        />
      </Link>
      <YStack alignItems="flex-start" justifyContent="flex-start" flex={1} space={gridLayout ? 0 : '$1'} height="100%">
        <Link href={href} style={{ textDecoration: 'none' }}>
          <Text
            fontSize={gridLayout ? '$14' : '$12'}
            mt={gridLayout ? '$2' : 0}
            mb={gridLayout ? '$2' : 0}
            display="block"
            fontWeight="$semi-bold"
            numberOfLines={2}
            overflow="hidden"
          >
            {name}
          </Text>
        </Link>

        <Text fontSize="$12">{`${formatPrice({ amount: selectedVariant.price })} / ${
          selectedVariant?.unit?.quantity > 1 ? selectedVariant?.unit?.quantity : ''
        } ${selectedVariant?.unit?.name}`}</Text>

        {variants?.length > 0 && (
          <YStack mt={gridLayout ? '$2' : 0} maxWidth="100%">
            {/* If there is only one variant and it has a name */}
            {variants?.length === 1 && variants?.[0].name ? (
              <Text>{selectedVariant.name}</Text>
            ) : variants.length > 1 ? ( // If there are multiple variants
              <Select
                variant="rounded"
                items={variantOptions}
                size="sm"
                selectedItemId={variantId}
                placeholder="Select variant"
                onSelect={(item) => {
                  if (item) {
                    onChangeVariant(item.id.toString());
                  }
                }}
              />
            ) : // There could be one variant with no name, render nothing
            null}
          </YStack>
        )}
      </YStack>

      <YStack
        ml="auto"
        position={gridLayout ? 'absolute' : 'relative'}
        top={gridLayout ? 160 : 0}
        right={gridLayout ? 10 : 0}
      >
        {!loading && quantity > 0 ? (
          <CounterInput value={quantity} onChange={onChangeQuantity} autoFocus={autoFocus.current} />
        ) : loading ? (
          <Circle backgroundColor="$contrast" width="$8" height="$8">
            <Spinner color="$background" />
          </Circle>
        ) : (
          <Button
            testID={`product-list-item-add-button-${variantId}`}
            icon="plus"
            label="Add"
            onPress={handleOnAddItem}
          />
        )}
      </YStack>
    </XStack>
  );
};

export default ProductListItem;
